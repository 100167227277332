import * as React from "react";
import {Image} from "../../image";
import {gsap} from "gsap";
import {useEffect, useRef} from "react";
import { withPrefix } from 'gatsby';

const WorkItemThumb = (props) => {
    let mainContainer = useRef();
    let thumb = useRef();
    let scroll = useRef();

    useEffect(() => {
        let firstStepComplete = false;

        let enteringAnimation = () => {
            gsap.to(mainContainer.current, {
                x: "-100%",
                duration: 2,
                ease: "linear"
            })
            gsap.to(scroll.current, {
                backgroundPositionX: `-${thumb.current.clientWidth}px`,
                duration: 2,
                ease: "linear",
                onComplete: () => {
                    firstStepComplete = true;
                    gsap.fromTo(scroll.current,
                {
                            backgroundPositionX: `-${thumb.current?.clientWidth ?? 0}px`,
                        },
                        {
                            duration: 8,
                            ease: "linear",
                            repeat: -1,
                            backgroundPositionX: `-=${(thumb.current?.clientWidth ?? 0) * 4}px`,
                        }
                    )
                }
            })
        }
        let leavingAnimation = () => {
            gsap.killTweensOf(scroll.current);
            gsap.killTweensOf(mainContainer.current);
            let reset = () => {
                firstStepComplete = false;
                gsap.to(scroll.current, {
                    backgroundPositionX: `0px`,
                    duration: 1,
                    ease: "linear"
                });
                gsap.to(mainContainer.current, {
                    x: "0%",
                    duration: 1,
                    ease: "linear"
                })
            }

            if(firstStepComplete) {
                if(thumb.current && scroll.current) {
                    gsap.to(scroll.current, {
                        backgroundPositionX: `-${thumb.current.clientWidth * 1}px`,
                        duration: 1,
                        ease: "linear",
                        onComplete: reset
                    })
                }
            }
            else {
                reset();
            }
        }

        thumb.current.addEventListener('mouseenter', enteringAnimation);
        thumb.current.addEventListener('mouseleave', leavingAnimation);
    }, []);

    return (
        <div ref={thumb} className={"thumb"}>
            <div ref={mainContainer} className={"thumb-main-container"}>
                <Image src={props.image} alt={props.name} />
            </div>
            {props.name && (
                <div className="marquee">
                    <div className="marquee-content">
                        <span>{(new Array(40)).fill(`${props.name} • `).join("")}</span>
                        <span>{(new Array(40)).fill(`${props.name} • `).join("")}</span>
                    </div>
                </div>
            )}
            <div ref={scroll} className={"scroll"} style={{backgroundImage: `url(${withPrefix(props.scroll)})`}}></div>
        </div>
    );
}

export default WorkItemThumb;
