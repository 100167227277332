import React, { useEffect, useRef } from "react"
import { enterLeft, exitDirection, EXIT_DIRECTION, fadeIn, fadeOut } from "../../utils/animation";
import { buildSeo } from "../../utils/seo";
import LinkFade from "../../components/linkfade";
import WorkItemThumb from "../../components/section/items/work-item-thumb";
import SecondaryListPageContent from "./fragments/secondary-list-page-content";
import {MarqueeTitle} from "../../components/cases/marquee-title";


/**
 * Render Category page
 * On first page show first article as Heading
 * List next articles in a list
 *
 * @param pageContext CategoryPageContext
 * @return {*}
 * @constructor
 */
const ListPage = ({ pageContext, transitionStatus, entry, exit }) => {
  let { articles} = pageContext; 
  
  let projects_elements = useRef([]);
  let project_list = useRef();

  useEffect(() => { 
      if(transitionStatus === "entering") { 
        fadeIn(project_list.current);

        projects_elements.current.forEach(value => { 
          enterLeft(value); 
        }) 
      }
      else if(transitionStatus === "exiting") {  
        fadeOut(project_list.current);

        projects_elements.current.forEach(value => { 
          exitDirection( EXIT_DIRECTION.right, value);
        }) 
      }
  }, [transitionStatus]);

  return (
    <section  className="container project-list">
      {buildSeo({
        title: 'Toutes mes réalisations',
        metaTitle: "Projets et réalisations Freelance",
        description: "Les différents projets sur lesquels j'ai pu travailler en tant que freelance en développement, en design ou en conception"
      })} 
      <div  ref={project_list}>
        <MarqueeTitle text={"Mes réals. Mes réals. Mes réals. Mes réals. Mes réals. Mes réals."}/>
        <h1 className={"h2 md:max-w-50"}>Des projets mêlants<br/>conception, design<br/>& développement</h1>
        <section className={"project-grid"}>
            {articles.filter(v => v.frontmatter.featured_listing).map((value, index) => {
              return <ImportantListPageContent   key={index} value={value} index={index} projects_elements={projects_elements}/>
            })} 
        </section>
        <section className={"project-secondary"}>
            {articles.filter(v => !v.frontmatter.featured_listing).map((value, index) => {
              return <SecondaryListPageContent   key={index} value={value} index={index} projects_elements={projects_elements}/>
            })}
        </section>
      </div>
    </section>
  )
}

const ImportantListPageContent = (({value, index, projects_elements}) => {
  return (<LinkFade   key={index} url={"../" +value.fields.path} onClick={() => console.log("CLOCK")}>
    <div ref={(el) => { projects_elements.current[index] = el; }} className={"project-item"}>
      <div className={"work-thumb"}>
        <WorkItemThumb image={value.frontmatter.thumbnail} name={value.frontmatter.title} scroll={value.frontmatter.scroll}/>
      </div>
    </div>
  </LinkFade>)
});



export default ListPage
