import LinkFade from "../../../components/linkfade";
import {Image} from "../../../components/image";
import React, {useEffect, useRef} from "react";
import {gsap} from "gsap";

const SecondaryListPageContent = (({value, index, projects_elements}) => {
    let containerRef = useRef();
    let imageRef = useRef();

    useEffect(() => {
        if(containerRef && containerRef.current) {
            containerRef.current.addEventListener('mouseenter', () => {
                gsap.fromTo(imageRef.current, {alpha: 0, scale: 0.8}, {alpha: 1, scale: 1});
            });
            containerRef.current.addEventListener('mouseleave', () => {
                gsap.fromTo(imageRef.current, {alpha: 1, scale: 1}, {alpha: 0, scale: 0.8});
            });
            containerRef.current.addEventListener('mousemove', (e) => {
                var mouseOffsetX = e.layerX  - containerRef.current.offsetLeft;
                var mouseOffsetY = e.layerY  - containerRef.current.offsetTop;

                let height = containerRef.current.clientHeight;
                let width = containerRef.current.clientWidth;

                let x_position = Math.min((width - imageRef.current.clientWidth), mouseOffsetX);

                gsap.to(imageRef.current, {y: `${(mouseOffsetY - height/2)}`, x: (x_position * 0.8 - 200)});
            });
        }
    }, [])


    return (<LinkFade key={index} url={"../" +value.fields.path}>
        <div ref={containerRef} className={"project-item"}>
            <div ref={imageRef} className={"project-thumb"} style={{opacity:0}}>
                <Image src={value.frontmatter.thumbnail}/>
            </div>
            <span className={"project-name"}>{value.frontmatter.title}</span><br/>
            <span className={"project-tasks"}>{value.frontmatter.tasks.join(" & ")}</span>
        </div>
    </LinkFade>)
});
export default SecondaryListPageContent;
