import gsap from "gsap";

export function exitLeft(element) {
    let bounding = element.getBoundingClientRect(); 
    gsap.to(element, {
        left: "-=" + (bounding.left + bounding.width)
    });
}

export function exitRight(element) {
    let bounding = element.getBoundingClientRect(); 
    gsap.to(element, {
        left: "+=" + (window.innerWidth - bounding.left)
    });
}

export function enterLeft(element) {
    let bounding = element.getBoundingClientRect(); 
    gsap.fromTo(element, {
        left: "-=" + (bounding.left + bounding.width)
    }, {left: 0});
}

export function fadeIn(element) {
    gsap.fromTo(element, {opacity: 0}, {opacity: 1});
}

export function fadeOut(element) {
    gsap.to(element, {opacity: 0});
}

export function exitDirection(direction, element) {
    if(direction === EXIT_DIRECTION.right) {
        exitRight(element);
    }
    else {
        exitLeft(element);
    }
}

export const EXIT_DIRECTION = {
    left: "left",
    right: "right"
}